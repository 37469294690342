@font-face {
  font-family: Helvetica;
  src: url(fonts/Helvetica.ttf);
}

@font-face {
  font-family: Helvetica-Bold;
  src: url(fonts/Helvetica-Bold.ttf);
}

body {
  margin: 0;
  font-family: Helvetica;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove blue box from download link */
:focus {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

/* Remove outline from hamburger menu and dark mode toggle */
button:focus,
button:active,
button:focus {
    outline: none;
    box-shadow: none;
}

/* Remove blue highlight from dropdown items */
.dropdown-item:active {
  background-color: white;
}

/* Remove underline on dropdown items */
a:hover {
  text-decoration: none;
}

/* Set background color of dropdown menu to something that works for light/dark mode */
.navbar-nav .dropdown-menu {
  background-color: #dbdbdb
}
